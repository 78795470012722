<template>
    <div class="admin-settings-trnaslations">
        <b-card class="cms-pages-admin" :title="$t('Templates')">
            <b-row>
                <b-col cols="12">
                    <b-button variant="primary" @click="openModal">Add New</b-button>
                </b-col>
                <b-col cols="12">
                    <good-data-table
                        ref="ai_templates"
                        :mode="'local'"
                        :no-serial="true"
                        :columns="aiTemplateColumns"
                        :load-local-data="true"
                    />
                </b-col>
            </b-row>
        </b-card>
        <b-modal v-model="toggelModal" size="lg" :no-close-on-backdrop="true" :title="$t('Ai Custom Template')" hide-footer>
            <b-row>
                <b-col cols="12">
                    <validation-observer ref="aiTemplateForm">
                        <b-form @submit.prevent="submitForm">
                            <b-row class="d-flex justify-content-center">
                                <b-col cols="12">
                                    <b-row>
                                        <b-col cols="12">
                                            <b-form-group :label="$t('Group')" label-for="ai_template_group_id">
                                                <validation-provider #default="{ errors }" :name="$t('Group')"
                                                    rules="required">
                                                    <b-form-select id="ai_template_group_id" v-model="formData.ai_template_group_id"
                                                        :options="groupOpts" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12">
                                            <b-form-group :label="$t('Label')" label-for="label">
                                                <validation-provider #default="{ errors }" :name="$t('Label')"
                                                    rules="required">
                                                    <b-form-input id="label" v-model="formData.label"
                                                        :state="errors.length > 0 ? false : null"
                                                        :placeholder="$t('Label')" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12">
                                            <b-form-group :label="$t('Prompt')" label-for="Prompt">
                                                <validation-provider #default="{ errors }" :name="$t('Prompt')"
                                                    rules="required">
                                                    <b-form-textarea id="prompt" v-model="formData.prompt"
                                                        :state="errors.length > 0 ? false : null"
                                                        :placeholder="$t('Prompt')" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                                <small class="txet">
                                                    Include dynamic variable for inputs as <code>{{input_name}}</code>
                                                </small>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12">
                                            <label for="" class="mb-0"><strong>Fields</strong></label>
                                            <template v-if="fields.length > 0">
                                                <div class="row border m-1 pt-1" v-for="(field, fk) in fields" :key="`form_fields_${fk}`">
                                                    <div class="col-12 mb-2">
                                                        <label for="" class="mb-0">Type</label>
                                                        <b-form-radio-group :options="textOrTextAreaOpts" v-model="fields[fk].type" :name="`${fk}_field_type`" />
                                                    </div>
                                                    <div class="col-12 col-md-12">
                                                        <b-form-group :label="$t('Enter field Label')">
                                                            <b-form-input v-model="fields[fk].label" :placeholder="$t('Enter field Label')" />
                                                        </b-form-group>
                                                    </div>
                                                    <div class="col-12 col-md-12">
                                                        <b-form-group :label="$t('Enter field input name')">
                                                            <b-form-input v-model="fields[fk].name" :placeholder="$t('Enter field input name')" />
                                                        </b-form-group>
                                                    </div>
                                                    <div class="col-12 col-md-12">
                                                        <b-form-group :label="$t('Enter placeholder text')">
                                                            <b-form-input v-model="fields[fk].placeholder" :placeholder="$t('Enter placeholder text')" />
                                                        </b-form-group>
                                                    </div>
                                                    <div class="col-12 pb-1 d-flex justify-content-end">
                                                        <b-button size="sm" variant="danger" @click="removeField(fk)">Remove</b-button>
                                                    </div>
                                                </div>
                                            </template>

                                            <div class="row">
                                                <div class="col-12 d-flex justify-content-end">
                                                    <b-button variant="outline-primary" size="sm" @click="addNewField">Add New</b-button>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <b-form-group :label="$t('Description')" label-for="Description">
                                                <validation-provider #default="{ errors }" :name="$t('Description')"
                                                    rules="required">
                                                    <b-form-textarea id="Description" v-model="formData.description"
                                                        :state="errors.length > 0 ? false : null"
                                                        :placeholder="$t('Description')" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12">
                                            <b-form-group
                                                :label="$t('Icon')"
                                                label-for="icon"
                                            >
                                                <b-img 
                                                    v-if="!isEmpty(editData.icon)"
                                                    :src="`${$apiUrl}${editData.icon}`" 
                                                    class="mt-2 float-right"
                                                    width="150"
                                                    alt="basic svg img" 
                                                />
                                                <b-form-file
                                                    id="icon"
                                                    v-model="icon"
                                                    placeholder="Choose a file or drop it here..."
                                                    drop-placeholder="Drop file here..."
                                                />
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12">
                                            <b-button variant="success" type="submit" class="float-right">
                                                {{ $t('Submit') }}
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
    
<script>
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from '@/utilities';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    required, integer
} from '@validations'
const textOrTextAreaOpts = [
    {text: 'Text', value: 'text'},
    {text: 'Textarea', value: 'textarea'},
]
export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        GoodDataTable
    },
    data: () => ({
        textOrTextAreaOpts,
        toggelModal: false,
        formData: {
            ai_template_group_id: null,
            label: null,
            description: null,
            prompt: null,
        },
        editData: {},
        icon: null,
        fields: [],
        input_name: '{{input_name}}',
        required, integer
    }),
    computed: {
        ...mapGetters('aiTemplate', [
            'aiTemplatesLoaded',
            'aiTemplateGroups',
            'aiTemplates',
        ]),
        groupOpts: {
            get() {
                return this.aiTemplateGroups.map(i => ({
                    text: i.label,
                    value: i.id
                }))
            }
        },
        aiTemplateColumns: {
            get() {
                return [
                    {
                        field: 'label',
                        label: 'Label',
                    },
                    {
                        field: 'prompt',
                        label: 'Prompt',
                    },
                    {
                        field: 'fields',
                        label: 'fields',
                        useResolver: true,
                        useHtml: true,
                        renderer: props => {
                            const { fields } = props
                            if(isEmpty(fields)) return null
                            return eval(fields).map(i => i.label).join(', ');
                        },
                    },
                    {
                        label: 'Action',
                        field: 'action',
                        sortable: false,
                        type: 'dropdown',
                        actions: [
                            {
                                isIcon: true,
                                iconHtml: this.$helpers.getIcons('edit'),
                                text: 'Edit',
                                action: props => {
                                    this.openModal(props)
                                },
                            },
                        ],
                    },
                ]
            }
        }
    },
    mounted() {
        if(!this.aiTemplatesLoaded){
            showLoader()
            this.getAiTemplateGroups().then(r => {
                hideLoader()
            }).catch(e => {
                hideLoader()
            })
        }
    },
    methods: {
        isEmpty,
        ...mapActions('aiTemplate', [
            'getAiTemplateGroups',
            'storeNewTempalte',
            'updateTempalte',
        ]),
        async openModal(i){
            this.resetForm();
            if(!isEmpty(i)){
                this.fields = !isEmpty(i.fields) ? eval(i.fields) : [];
                const {
                    ai_template_group_id,
                    label,
                    description,
                    prompt,
                } = i;
                this.formData = {
                    ai_template_group_id,
                    label,
                    description,
                    prompt,
                }
                this.editData = i
            }
            this.toggelModal = true;
        },
        submitForm() {
            this.$refs.aiTemplateForm.validate().then(success => {
                if (success) {
                    showLoader()
                    let formAction = this.storeNewTempalte;
                    this.formData.fields = JSON.stringify(this.fields);
                    if(!isEmpty(this.icon)){
                        this.formData.icon = this.icon;
                    }
                    if(!isEmpty(this.editData.id)){
                        this.formData.id = this.editData.id;
                        formAction = this.updateTempalte
                    }
                    formAction(this.formData).then(({ data }) => {
                        if (data) {
                            if (data.statusCode === '200') {
                                showSuccessNotification(this, "Template added successfully")
                                this.$refs.couponTable.loadItems()
                            }
                        }
                    }).catch(error => {
                        showErrorNotification(this, error)
                    })
                    hideLoader()
                    this.toggelModal = false
                }
            });
        },
        addNewField(){
            this.fields.push({
                type: 'text',
                name: null,
                placeholder: null,
                label: null,
            });
        },
        resetForm(){
            this.fields = [];
            this.icon = null;
            this.formData = {
                ai_template_group_id: null,
                label: null,
                description: null,
                prompt: null,
            }
            this.editData = {};
        },
        removeField(fk){
            this.fields.splice(fk, 1);
        }
    },
    watch: {
        aiTemplates: {
            handler(nv){
                this.$refs.ai_templates.setLocalData(nv)
            },
        }
    }
}
</script>
    